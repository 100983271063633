import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

import { Page } from '../components/page';
import { HeroImage } from '../components/hero_image';
import { TGatsbyImageFluid } from '../typedefs/gatsby-image';
import { RichText } from '../components/richt_text';
import { Map } from '../components/map';

import Tram from '../icons/tram.svg';
import Train from '../icons/train.svg';
import Car from '../icons/car.svg';

import './index.scss';

type TIndexPageProps = {
  readonly data: {
    readonly heroImage: TGatsbyImageFluid;
    readonly heroImageMobile: TGatsbyImageFluid;
    readonly dividerImage: TGatsbyImageFluid;
  };
};

const position = {
  lat: 47.07103990864156,
  lng: 15.427547757144065,
};

const IndexPage: React.FunctionComponent<TIndexPageProps> = ({ data }: TIndexPageProps) => {
  return (
    <Page
      seoDescription="Dr. Mario Sollhart - Rechtsanwalt | Eingetragener Treuhänder. Ihr Ansprechpartner zum Thema Recht im Herzen von Graz."
      preloadMaps={true}
    >
      <HeroImage
        image={data.heroImage}
        imageMobile={data.heroImageMobile}
        altText="Dr. Mario Sollhart"
        imgStyle={{ objectPosition: '50% 50%' }}
      >
        Dr. Mario Sollhart
      </HeroImage>
      <RichText>
        <h1 className="homepage_title">
          <span className="name">Dr. Mario Sollhart</span>
          <span className="profession">Rechtsanwalt | Eingetragener Treuhänder</span>
        </h1>
        <p>
          Als langjährig tätiger Rechtsanwalt biete ich unterstützt durch ein effizientes
          Rechtsanwaltskanzleiteam umfassende Beratungen und Vertretung für Ihre Rechtsfragen bzw.
          Rechtsprobleme an.
        </p>
        <p>
          Situiert in der Annenstraße in Graz und ausgestattet mit modernster IT-Infrastruktur
          werden in meiner Rechtsanwaltskanzlei schnell und zielgerichtet Lösungen in sämtlichen
          Rechtsbereichen, die Sie als Privatperson und/oder als Unternehmer bzw. Ihr Unternehmen
          betreffen können, erarbeitet und umgesetzt.
        </p>
        <p>
          Durch meine Vortragstätigkeit an der Fachhochschule Burgenland im Vertrags- und Baurecht
          ist es mir möglich hierbei nicht nur die aktuelle Gesetzgebung und Rechtsprechung, sondern
          auch die verschiedenen Lehrmeinungen zu berücksichtigen.
        </p>
        <p>
          Mein Rechtsanwaltskanzleiteam und ich stehen Ihnen gerne als kompetente Ansprechpartner in
          den Bereichen Abwehr von Schadenersatz- und Gewährleistungsansprüchen, Arbeitsrecht,
          Vertrags- und IT-Recht, Verwaltungsverfahren (Bau- und/oder Betriebsanlageverfahren), bei
          der Durchsetzung von Schadenersatzansprüchen nach Verkehrsunfällen, Scheidungs-,
          Unterhalts-, und Obsorgerecht sowie Bauträger-, Immobilien- und Maklerrecht gerne zur
          Verfügung.
        </p>
        <div className="images_wrapper">
          <GatsbyImage
            image={data.dividerImage.childImageSharp.gatsbyImageData}
            className="services_image"
            alt="Bücher"
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </RichText>
      <RichText className="location">
        <h2>Attraktive Lage im Zentrum von Graz</h2>
        <p>
          Unsere Kanzlei besticht durch ihre gute Lage inmitten von Graz. Die Räumlichkeiten sind
          dank ihres zentralen Standorts auch mit öffentlichen Verkehrsmitteln bestens zu erreichen.
          Zudem befindet sich das Büro in angenehmer Gehweite vom Hauptbahnhof.
        </p>
        <ul>
          <li>
            <Tram />
            <span>Straßenbahn/Bus: Haltestelle Roseggerhaus</span>
          </li>
          <li>
            <Car />
            <span>
              Parkmöglichkeiten: Tiefgarage Roseggerhaus - Graz | APCOA oder <br />
              gebührenpflichtige Parkplätze im Innenhof (Zufahrt über die Elisabethinergasse)
            </span>
          </li>
          <li>
            <Train />
            <span>ca. 10 Minuten Fußweg vom Hauptbahnhof</span>
          </li>
        </ul>
        <div className="map_wrapper">
          <Map
            center={position}
            zoom={17}
            apiKey="AIzaSyDK99XFjcbjFuJC8ljeitbc-9DsSn72PD0"
            title="Kanzlei Dr. Mario Sollhart"
            text="Annenstraße 25"
            ctaText="Anfahrt"
            href="https://www.google.at/maps/dir//Annenstra%C3%9Fe+25,+8020+Graz/@47.0706251,15.4251815,16.5z/data=!4m9!4m8!1m0!1m5!1m1!1s0x476e35765c07e0e1:0x4cb89395277766d9!2m2!1d15.4275645!2d47.0709828!3e3"
            mapContainerClassName="map_wrapper"
          />
        </div>
      </RichText>
    </Page>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    heroImage: file(relativePath: { eq: "rechtsanwalt_mario_sollhart.png" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: BLURRED
          transformOptions: { cropFocus: NORTHEAST }
          layout: FULL_WIDTH
        )
      }
    }
    heroImageMobile: file(relativePath: { eq: "rechtsanwalt_mario_sollhart_mobile.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
      }
    }
    dividerImage: file(relativePath: { eq: "buecher_schraeg.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          transformOptions: { cropFocus: NORTH }
          layout: FULL_WIDTH
          placeholder: BLURRED
        )
      }
    }
  }
`;
